import 'bootstrap/dist/css/bootstrap.min.css'
import '../stylesheet/style.css'
import 'hover.css/css/hover-min.css'
import React from 'react'
import { Link } from 'gatsby'
import { navigate } from 'gatsby'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faAngleDown,
  faAngleUp,
  faSearchPlus,
  faCar,
  faSkiing,
  faGamepad,
  faBook,
  faCarSide,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'

import styled from 'styled-components'

import {
  Row,
  Col,
  Nav,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from 'reactstrap'
import Scrollspy from 'react-scrollspy'
import { Layout, StyledContainer } from '../components/layout'
import SEO from '../components/seo'
import { getSeperatorWithHeight, BodyText1 } from '../components/text_style'
import bannerImage from '../images/Banner.svg'
import logoImage from '../images/New_logo.svg'
import bpresentImage from '../images/B-Present.jpg'
import infovisImage from '../images/the-impatient-list.jpg'
import castieImage from '../images/Castie_Intro.png'
import ntlImage from '../images/ntl_cover.png'
import rrImage from '../images/rrImage.png'
import pawesomeImage from '../images/pawesomeImage.png'
import googleImage from '../images/Google.png'
import {
  displayFont,
  displayFontWeight,
  textFont,
  textFontWeight,
} from '../components/site_style'
import Footer from './footer'
import { H4 } from '../components/text_style'
import { motion } from 'framer-motion'

library.add(faAngleUp)
library.add(faAngleDown)
library.add(faSearchPlus)
library.add(faCarSide)
library.add(faSkiing)
library.add(faGamepad)
library.add(faBook)
library.add(faChevronDown)

let Seperator80px = styled.div`
  height: 80px;
`

let SideBar = styled.nav`
  position: fixed;
  z-index: 1;
  padding-top: 24px;
  width: 200px;

  .nav > li > a {
    color: #999;
    padding: 4px 20px;
    font-size: 16px;
    font-weight: 400;
  }

  .nav .nav > li > a {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 30px;
    font-size: 12px;
  }

  .nav > .active > a {
    color: #563d7c;
  }

  .nav > li > a:hover,
  .nav > li > a:focus {
    color: #563d7c;
    text-decoration: none;
    background-color: transparent;
    border-left: 2px solid #563d7c;
  }

  @media screen and (max-width: 800px) {
    .nav {
      display: none;
    }
  }
`

const TagBackground = styled.span`
  background-color: #fff8f3;
  color: #dc8240;
  border-radius: 5px;
  font-family: ${textFont};
  font-weight: ${textFontWeight.regular};
  font-size: 13px;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 10px;
  margin-top: 4px;
  &:last-child {
    margin-right: 0;
  }
`

const ProjectCard = styled(Card)`
  && {
    margin-left: 15px;
    margin-right: 15px;
    box-shadow: 3px 2px 20px 0 #b1b1b1;
    border-radius: 8px;
    border-width: 0px;
    cursor: pointer;
    height: 100%;
  }
`

const ProjectCardTitle = styled(CardTitle)`
  && {
    font-size: 28px;
    font-family: ${displayFont};
    font-weight: ${displayFontWeight.bold};
    padding-left: 3px;
    padding-right: 3px;
  }
`

const ProjectDescription = styled(CardText)`
  padding-top: 16px;
  font-family: ${textFont};
  font-weight: ${textFontWeight.regular};
  font-size: 16px;
  line-height: 25px;
`

const bPresentProjectInfo = {
  endpoint: '/bpresentv2/',
  title: 'B-Present',
  tags: ['App Design', 'UX Design', 'UX Research'],
  description: `A social game combined with big data on the mobile to help gift buyers’ choose personalized gifts for their close relations`,
  image: bpresentImage,
}

const pawesomeProjectInfo = {
  endpoint: '/pawesome/',
  title: 'Pawesome Companion',
  tags: ['UX Design', 'Rapid Prototyping', 'Project Management'],
  description:
    'An mobile app which allows pet owners to connect with trustful others within a community to have a collaborative short-term pet sitting',
  image: pawesomeImage,
}

const rrProjectInfo = {
  endpoint: '/rr/',
  title: 'Research Repository',
  tags: ['UX Research', 'UX Design'],
  description:
    'A web app which enables employees within Focus Brands to share their research in a way that others can easily locate and use',
  image: rrImage,
}

const infovisProjectInfo = {
  endpoint: '/infovis/',
  title: 'The Impatient List',
  tags: ['Information Visualization', 'UX Design'],
  description: `A website which visualizes information to call general public’s
  attention to patients who need kidney transplant in United States.`,
  image: infovisImage,
}

const castieProjectInfo = {
  endpoint: '/castie/',
  title: 'Castie',
  tags: ['Voice Interaction Design', 'UX Design'],
  description: `A voice assistant which enables commuters to take notes of their
  favourite content in the podcasts they listen to on their way to
  work.`,
  image: castieImage,
}

const ntlProjectInfo = {
  endpoint: '/ntl/',
  title: 'NTL Lake Condition',
  tags: ['Mobile App Design', 'UX Engineer'],
  description: `An mobile app which distributes the real-time weather information
  of three different lakes to sailors, researchers and fishermen.`,
  image: ntlImage,
}

const googleProjectInfo = {
  endpoint: '/googleintern/',
  title: 'Google Internship',
  tags: ['Rapid prototyping', 'Interaction Design'],
  description: `My two projects related to tablet interaction at Google Chrome OS as a UX Engineer taught me how to bridge the gap between design and engineer.`,
  image: googleImage,
}

const Project = ({ endpoint, title, tags, description, image }) => {
  return (
    <React.Fragment>
      {/* onClick={()=>navigate('/bpresent/')}  */}
      <ProjectCard className={'hvr-grow'} onClick={() => navigate(endpoint)}>
        <CardImg
          style={{
            borderRadius: '8px 8px 0 0',
            borderWidth: `0px`,
            marginBottom: `12px`,
          }}
          top
          width="100%"
          src={image}
          alt={title}
        />
        <CardBody
          style={{ borderWidth: `0px`, padding: '20px 40px 50px 40px' }}
        >
          <ProjectCardTitle>{title}</ProjectCardTitle>
          <CardSubtitle
            style={{
              marginBottom: `10px`,
              marginTop: `5px`,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {tags.map((tag, i) => (
              <TagBackground key={tag + i}>{tag}</TagBackground>
            ))}
          </CardSubtitle>
          <ProjectDescription>{description}</ProjectDescription>
        </CardBody>
      </ProjectCard>
    </React.Fragment>
  )
}

const IndexPage = () => {
  const [scrolling, setScrolling] = React.useState(false)
  
  return (
  <Layout frontPage={true} onScroll={()=>{console.log("scrolling"); setScrolling(true)}}>
    <SEO title="Junjie Xu" keywords={[`gatsby`, `application`, `react`]} />

    {/* {getSeperatorWithHeight(50)} */}
    <Row
      style={{
        height: '100vh',
        backgroundImage:
          'linear-gradient(#EC7224 0%, #F19C61 75%, rgba(255,255,255,0.97) 100%)',
      }}
    >
      <Col
        md={{ size: 5, offset: 1 }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div style={{display:"flex", flexDirection:"row"}}>
        <H4 style={{ color: 'white', fontSize: '2rem' }}>
          Hi, I'm a {' '}

          <div className="rotater-container">
            {' '}
            {/* UX{' '} */}
            <div className="rotater">
              {' '}
              <div className='title'> UX designer </div>{' '}
              <div className="title"> UX prototyper </div>{' '}
              <div className="title"> UI developer </div>{' '}
            </div>{' '}
          </div>{' '}
          <br />
        </H4>
        {/* <H4 style={{ color: 'white', fontSize: '2rem' }}>
         
        </H4> */}
        </div>

        <BodyText1
          style={{ marginTop: '2rem', color: 'white', fontSize: '1.75rem' }}
        >
          I'm passionate about improving software user experience through design and engineering.
        </BodyText1>
      </Col>

      <Col md={5} className="d-flex justify-content-center align-items-center">
        <motion.img
          style={{ scale: 0.9 }}
          animate={{
            rotate: 360,
            transition: {
              duration: 10,
              loop: Infinity,
              ease: 'linear',
            },
          }}
          src={logoImage}
        />
      </Col>
      <Col
        md={12}
        style={{
          position: 'absolute',
          bottom: '40px',
          display: 'flex',
          justifyContent: 'center',
          opacity: scrolling ? 0 : 1,
          transition: "opacity 0.35s ease-in-out"
        }}
      >
        <FontAwesomeIcon
          size="3x"
          icon={'chevron-down'}
          color={'rgba(0,0,0,0.2)'}
          className="arrow-down"
        />
      </Col>
    </Row>
    {getSeperatorWithHeight(50)}

    <Row>
      <Col md={2}></Col>
      <Col
        md={4}
        style={{
          // textAlign: 'center',
          justifyContent: 'flex-start',
          color: '#DE6D25',
          fontSize: `36px`,
          lineSpacing: `-0.5px`,
          fontFamily: displayFont,
          fontWeight: displayFontWeight.regular,
        }}
      >
        Projects
      </Col>
    </Row>

    {getSeperatorWithHeight(60)}

    {/* Put things here */}
    {/* First row */}

    <Row>
      <Col md={2}></Col>

      <Col md={4}>
        <Project {...googleProjectInfo} />
      </Col>

      <Col md={4}>
        <Project {...bPresentProjectInfo} />
      </Col>
    </Row>

    {getSeperatorWithHeight(80)}

    <Row>
      <Col md={2}></Col>
      <Col md={4}>
        <Project {...pawesomeProjectInfo} />
      </Col>

      <Col md={4}>
        <Project {...infovisProjectInfo} />
      </Col>
    </Row>

    {getSeperatorWithHeight(80)}

    <Row>
      <Col md={2}></Col>
      <Col md={4}>
        <Project {...rrProjectInfo} />
      </Col>

      <Col md={4}>
        <Project {...ntlProjectInfo} />
      </Col>
    </Row>

    {getSeperatorWithHeight(80)}

    <Row>
      <Col md={2}></Col>
      <Col md={4}>
        <Project {...castieProjectInfo} />
      </Col>
    </Row>

    {getSeperatorWithHeight(400)}

    <Footer />
  </Layout>
)
      }
export default IndexPage
